.green-button {
    cursor: pointer !important;
    border-radius: 30px !important;
    font-family: "ProximaNova-Regular", sans-serif;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    background-color: #0db68c !important;
    border: none !important;
    white-space: nowrap !important;
}

.thin-button {
    padding: 4px 64px !important;
}

.thick-button {
    margin: 8px;
    padding: 16px 64px !important;
}

.fixed-top-right {
    z-index: 999 !important;
    position: fixed !important;
    top: 8px;
    right: 8px;
}

.float-right {
    float: right !important;
}

.info-icon {
    font-size: 1.4em;
    position: absolute;
    left: -50px;
    z-index: 999 !important;
    margin: 24px 16px;
}

#button-container {
    position: relative;
    height: 48px;
    width: 225px;
    float: right;
    white-space: nowrap !important;
}
