footer {
    width: 100%;
    padding: 8px;
    margin: 0;
    margin-top: 16px;
    color: white;
    background: #283940;
    font-size: 0.75rem;
}

footer .row {
    padding-bottom: 0;
}

footer .row .col-md p {
    margin-bottom: 8px;
}

.footer-logo {
	max-height: 24px;
	float: left;
	margin-bottom: 8px;
}

.translucent {
	opacity: 0.6;
}
