@font-face {
  font-family: "ApexSerif-Medium";
  src: url("/fonts/ApexSerif-Medium.woff")
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("/fonts/ProximaNova-Regular.woff2")
}

html, body, h1 {
  margin: 0;
  padding: 0;
}

html, body {
    overflow-x: hidden;
}
