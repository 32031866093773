.slide-container, .slide-container div, .react-slideshow-container {
    height: 491px;
}

.each-slide {
    margin-top: 0;
    padding-top: 0;
}

.slide {
    background-image: url('/img/header.jpg');
    background-size: fill;
    background-position: 50%;
    border-bottom-right-radius: 96px;
    background-repeat: no-repeat;
    max-width: 1200px;
    margin: 0 auto;
}

.slider-title {
    color: #0084cb;
    padding-top: 12px;
    padding-left: 8px;
    font-size: 3rem !important;
}

.slider-body {
    font-size: 2.5em;
    color: black;
    margin-top: -8px;
    margin-left: 12px;
}

.slider-title-container {
    width: 100%;
    position: relative;
    top: 15vh;
    box-sizing: border-box;
    height: fit-content !important;
    background-color: rgba(255, 255, 255, 0.5);
    text-shadow: 0 0 8px #FFF;
    align-items: center;
    text-align: center;
}

.title-caption {
    color: #999;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 0;
}
