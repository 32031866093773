#alert-banner {
    display: block;
    overflow: visible;
    min-height: max-content;
    width: 100%;
    background-color: #0db68c;
    color: white;
    padding: 8px;
    line-height: 28px;
}

#alert-banner-close-button {
    float: right;
    font-size: 16px;
    height: 100%;
    width: 32px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    vertical-align: middle;
}
