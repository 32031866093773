.gray-box {
    margin: 48px 48px 32px 48px;
    padding: 32px 24px;
    display: block;
    background-color: #e8f2f4;
    border-radius: 4px;
    border-bottom-left-radius: 75px;
    border-top-right-radius: 75px;
}

@media only screen and (min-width: 767px) {
    .right-divider-white {
        border-right: 2px solid #ffffff;
    }
}

@media only screen and (max-width: 767px) {
    .right-divider-white {
        border-right: 0 !important;
        border-bottom: 2px solid #ffffff;
        padding-bottom: 8px;
        margin-bottom: 24px;
        margin-right: 16px;
    }
    .gray-box {
        margin: 64px 8px;
        padding: 32px 24px;
    }
}
