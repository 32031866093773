#top-bar {
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;
    width: 100%;
    height: 48px;
    background-color: white;
    box-shadow: 0 0 4px gray;
}

#top-bar h5 {
    margin-left: 150px;
    margin-top: 6px;
    white-space: nowrap;
}

#logo-top {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 999;
    height: 4rem;
}
