.big-red-button {
    cursor: pointer !important;
    border-radius: 50px !important;
    font-family: "ProximaNova-Regular", sans-serif;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    background-color: #e74154 !important;
    padding: 24px 64px !important;
    margin-top: 16px;
    border: none !important;
    white-space: nowrap !important;
    position: relative;
}
