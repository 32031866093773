h1, h2, h3, h4, h5, h6 {
    font-family: "ApexSerif-Medium", serif;
}

body, p, div, span {
    font-family: "ProximaNova-Regular", sans-serif;
}

.hover-fade {
    opacity: 1;
}

.hover-fade:hover {
    opacity: 0.6;
}
