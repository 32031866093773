.hero-tabs-container {
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
}

.hero-tabs::before,
.hero-tabs::after {
    width: 28px;
    height: 28px;
    position: absolute;
    margin-bottom: -1px;
    bottom: 0;
    box-sizing: border-box !important;
}

.faded-text {
    font-style: italic;
    font-size: 1rem;
    color: #777;
    margin-top: -8px;
}
